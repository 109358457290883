// Flag to track whether the Back-To-Top button is visible
let visible = false;

/**
 * Show or hide the back-to-top button depending on where we're scrolled to.
 *
 */
function rethink() {
    // Decide what the state of the BTT button should now be
    const top = $(document).scrollTop();

    const newVisible = Boolean(top && (top > 50));

    // change if it's not what it should be
    if (newVisible !== visible) {
        // jquery.fadeTo seems to have different in and out speeds, so use separate calls
        if (newVisible) {
            $('#back-to-top').fadeIn(350);
        } else {
            $('#back-to-top').fadeOut(350);
        }

        visible = newVisible;
    }
}

/**
 * Setup the Back-To-Top button on this page
 *
 */
$(() => {
    $('#back-to-top').on('click', () => {
        window.scrollTo(0, 0);
    });

    rethink();

    $(window).on('scroll', rethink);
});
